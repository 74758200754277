import {environment} from '../environments/environment';
import {DateTime} from "ts-luxon";

export const invalidDateStr = 'INVALID-DATE';

export function formatDate(date: any, timeIncluded = false) {
    // https://stackoverflow.com/questions/3552461/how-do-i-format-a-date-in-javascript
    // return date.substring(0, 10);
    if (!date) return invalidDateStr;
    const d = new Date(date);
    // console.log('new date from string ', date, d);

    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();
    if (!timeIncluded) {
        return ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
            d.getFullYear();
        // return `${day < 10 ? '0' : ''}${day}/${month + 1 < 10 ? '0' : ''}${month + 1}/${year}`;
    } else {

        return ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
            d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
        // const hour = d.getHours();
        // const minutes = d.getMinutes();
        // return `${day < 10 ? '0' : ''}${day}/${month + 1 < 10 ? '0' : ''}${month + 1}/${year}
        //         ${hour < 10 ? '0' : ''}${hour}:${minutes < 10 ? '0' : ''}${minutes}`;
    }
}

export function showIsoDateAsParis(isoDate: string, withTime = true) {
    const parisTime = DateTime.fromISO(isoDate, {zone: 'Europe/Paris'});
    if (!withTime) {
        return parisTime.toFormat('dd-MM-yyyy');
    } else {
        return parisTime.toFormat('dd-MM-yyyy HH:mm');
    }
}


export function formatDateTime(isoDate: string, lang: string, withTime = true) {
    const time = DateTime.fromISO(isoDate, /*{zone: 'Europe/Paris'}*/);
    // if (!withTime) {
    //     return parisTime.toFormat('dd-MM-yyyy');
    // } else {
    //     return parisTime.toFormat('dd-MM-yyyy HH:mm');
    // }

    if (lang === 'en') {
       // return in US format
        if (!withTime) {
            return time.toFormat('MM-dd-yyyy');
        } else {
            return time.toFormat('MM-dd-yyyy HH:mm');
        }
    } else {
        // return in FR format
        if (!withTime) {
            return time.toFormat('dd-MM-yyyy');
        } else {
            return time.toFormat('dd-MM-yyyy HH:mm');
        }
    }
}

export function formatDateTimeAsParis(isoDate: string, lang: string, withTime = true) {
    const time = DateTime.fromISO(isoDate, {zone: 'Europe/Paris'});
    // if (!withTime) {
    //     return parisTime.toFormat('dd-MM-yyyy');
    // } else {
    //     return parisTime.toFormat('dd-MM-yyyy HH:mm');
    // }

    if (lang === 'en') {
        // return in US format
        if (!withTime) {
            return time.toFormat('MM-dd-yyyy');
        } else {
            return time.toFormat('MM-dd-yyyy HH:mm');
        }
    } else {
        // return in FR format
        if (!withTime) {
            return time.toFormat('dd-MM-yyyy');
        } else {
            return time.toFormat('dd-MM-yyyy HH:mm');
        }
    }
}
export function convertPickerDateToParisTime(pickerDate: Date, time: string) {
    // input: pickerDate 2024-06-08T17:00:00.000Z (PC local time is GMT+7)
    // localDate: 2024-06-09
    const localDateTime = DateTime.fromISO(pickerDate.toISOString()).toLocal();
    const hh = parseInt(time.split(':')[0]);
    const mm = parseInt(time.split(':')[1]);

    console.log('debug-time year, month, day', localDateTime.toString(), localDateTime.year, localDateTime.month, localDateTime.day, hh, mm);
    const parisTime = DateTime.fromObject({
        year: localDateTime.year,
        month: localDateTime.month,
        day: localDateTime.day,
        hour: hh,
        minute: mm
    }, {zone: 'Europe/Paris'});

    // const parisTime = DateTime.fromISO(pickerDate.toISOString().split('T')[0] + 'T' + time, {zone: 'Europe/Paris'});

    console.log('debug-time parisTime', parisTime.toISO());

    return parisTime.toJSDate();

    // const testTime = DateTime.fromISO("2024-06-09T14:02:00.000Z")
    // console.log('debug-time testTime', testTime.toISO());
}


export function parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function isCacheInvalid(timestamp: number | undefined, cacheTime: number = 0) {
    return !timestamp || Date.now() - timestamp > cacheTime;
}

export function getRenderedImage(name: string) {
    // console.log('renderImage: ', name, '::', `${environment.api}files/render/image/${name}`);
    if (!name) return '';
    return `${environment.api}files/render/image/${name}`
}

export function parseDescription(item: any): any {
    if (!item || typeof item !== 'string') return ''
    const result = item.split('\n')
    const description = result.splice(0, 1)
    const chat: any = []
    const regex = /[a-h0-9]{24}/gm
    result.forEach((item) => {
        // isAdmin:true&message:privet&date:1649248620092
        const parseMessage = item.split('&')
        const finalMsg = {
            isAdmin: false,
            message: '',
            date: new Date(),
            isImage: false
        }
        parseMessage.forEach(msg => {
            if (msg.includes('isAdmin', 0)) {
                finalMsg.isAdmin = msg.includes('true')
            } else if (msg.includes('message', 0)) {
                finalMsg.message = msg.replace('message:', '')
                const images = msg.match(regex)
                if (images && images.length === 1) {
                    finalMsg.isImage = true
                }
            } else if (msg.includes('date', 0)) {
                finalMsg.date = new Date(Number(msg.replace('date:', '')))
            }
        })
        chat.push(finalMsg)
    })
    return {description: description[0], chat}
}

export function getPreparedFilters(filters: any) {
    const result: any = {};
    filters?.forEach((entry: any) => {
        if (!entry.value || (entry.value.constructor === Array && !entry.value.length)) return;
        switch (entry.type) {
            case 'date':
                result[entry.fieldName] = {
                    $gte: new Date(entry.value).getTime(),
                    $lt: new Date(entry.value).getTime() + 86400000
                };
                break;
            case 'dateRange':
                if (!entry.value[0] && !entry.value[1]) return;
                const data: any = {};
                if (entry.value[0]) {
                    data.$gte = new Date(entry.value[0]).getTime();
                }
                if (entry.value[1]) {
                    data.$lt = new Date(entry.value[1]).getTime() + 86400000;
                }
                result[entry.fieldName] = data;
                break;
            case 'select':
                if (!entry.multiple) {
                    result[entry.fieldName] = {
                        $in: [entry.value]
                    }
                } else {
                    result[entry.fieldName] = {
                        $in: entry.value
                    }
                }
                break;
            case 'string':
                result[entry.fieldName] = {
                    $in: [entry.value]
                };
                break;
            case 'rating':
                result[entry.fieldName] = entry.value;
                break;
        }
    })
    return result;
}

export function generatePassword() {
    const charactersArray = 'a-z,A-Z,0-9,#'.split(',')
    let CharacterSet = ''
    let password = ''
    if (charactersArray.indexOf('a-z') >= 0) {
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
    }
    if (charactersArray.indexOf('A-Z') >= 0) {
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    }
    if (charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789'
    }
    if (charactersArray.indexOf('#') >= 0) {
        CharacterSet += '![]{}()%&*$#^<>~@|'
    }
    for (let i = 0; i < 15; i++) {
        password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
    }
    return password;
}

export function getFullAddress(data: any) {
    return data?.displayID || data?.name;
}

export function translateCategory(category: any, currentLang: string) {
    if (!category) {
        return 'ERROR'
    }
    if (category.data && category.data[currentLang]) {
        return category.data[currentLang]
    }
    return category.name
}

export function translateHousingType(type: any, currentLang: string) {
    if (!type) {
        return 'ERROR';
    }
    if (type.data && type.data[currentLang]) {
        return type.data[currentLang];
    }
    return type.name;
}


export function translateCategoriesArray(array: any[], currentLang: string) {
    if (!array?.length) {
        return 'TRANSLATE-ERROR'
    }
    let res: string[] = []
    array.forEach(category => {
        res.push(translateCategory(category, currentLang))
    })
    return res.join(', ')
}

export interface TableConfig {
    paging: {
        page: number
        limit: number
    }
    filters: any
    headers: {
        key: string
        title: string
        sort?: boolean
        cell?: any
        options?: boolean
        _sortValue?: number
    }[]
}

export function isAmbassadorCoAction(name: any) {
    return name === "AMBASSADOR|AMBASSADEUR|BOTSCHAFTER";
}

export function sortTimeSlot(time: any) {
    if (!Array.isArray(time)) return time;


    for (const slot of time) {
        if (!Array.isArray(slot.chosenTimes)) continue;
        slot.chosenTimes.sort((a: any, b: any) => {
            return new Date(`2000-01-01T${a}:00`).getTime() - new Date(`2000-01-01T${b}:00`).getTime();
        });
    }

    console.log('sort Time Slot', time);


    return time;
}

export function getObjectCounter(objectId: string | null): string {
    if (!objectId) return 'error-id';
    const id = objectId.toString();
    return id.slice(18, 24).toUpperCase();
}

export function getIncidentReadableName(incident: any, lang: string): string {
    // console.log('incidentXXX', incident);
    const title = incident?.incidentID?.title;
    if (!title || !title[lang]) return 'ERROR';
    let format = 'MM/dd/yy HH:mm';
    if (lang !== 'en') {
        format = 'dd/MM/yy HH:mm';
    }
    return title[lang] + ' - ' + DateTime.fromISO(incident.createdAt).setZone('Europe/Paris').toFormat(format);
}

export function getFullInterventionReadableName(intervention: any, lang: string): string {
    return getObjectCounter(intervention._id) + ' / ' + getIncidentReadableName(intervention, lang);
}

export function getIncidentReadableName2(incident: any, lang: string): string {
    // console.log('incidentXXX', incident);
    const title = incident?.incidentID?.title;
    if (!title || !title[lang]) return 'ERROR';
    let format = 'MM/dd/yy HH:mm';
    if (lang !== 'en') {
        format = 'dd/MM/yy HH:mm';
    }
    return title[lang] + ' - ' + DateTime.fromISO(incident.createdAt).toFormat(format);
}

export function getFullInterventionReadableName2(intervention: any, lang: string): string {
    return getObjectCounter(intervention._id) + ' / ' + getIncidentReadableName2(intervention, lang);
}
export function logPageName(page: string) {
    console.log('__page_name__', page);
}

export function translateSrvMultiLang(jsonStr: string, lang: string) {
    if (!jsonStr) return 'ERROR';

    try {
        const data = JSON.parse(jsonStr);
        if (data[lang]) {
            return data[lang];
        }
    } catch (e) {
        return jsonStr

    }
    return jsonStr
}
