import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ComponentsModule} from '../shared/components/components.module';
import {UserService} from '../shared/services/user.service';
import {RouterModule} from '@angular/router';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {NgIdleKeepaliveModule} from "@ng-idle/keepalive";
import { NgIdleModule } from '@ng-idle/core';
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json?cb=' + new Date().getTime());
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            // defaultLanguage: 'fr',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ComponentsModule,
        RouterModule,
        MatSnackBarModule,
        NgIdleModule.forRoot(), // Core idle functionality
        NgIdleKeepaliveModule.forRoot() // Optional for keep-alive pings to server

    ],
    providers: [
        UserService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
