export class SrRoles {
    static readonly ROLE_SUPER_ADMIN = 'superadmin';
    static readonly ROLE_ADMIN = 'admin';
    static readonly ROLE_FOA = 'familyOfficeAccount';
    static readonly ROLE_OWNER = 'owner';
    static readonly ROLE_REC = 'realEstateCompanies';
    static readonly ROLE_PROVIDER_E = 'provider-enterprise';
    static readonly ROLE_PROVIDER_AM = 'provider-ambassador';

    static readonly REC_AGENCY_ID = '__matchedAgencyId';
    static readonly OWNER_ID = '__matchedByOwnerId';


    static readonly skipErrorCheck = '__skipErrorCheck';
    // static readonly ROLE_TECH_PROVIDER = 'technicalProvider';
    // static readonly ROLE_TECH_PROVIDER_AMBASSADOR = 'technicalProviderAmbassador';

    // static readonly USER_ROLES_DISPLAY: { [key: string]: string } = {
    //     [this.ROLE_ADMIN]: 'Administrator',
    //     [this.ROLE_OWNER]: 'Owner',
    //     [this.ROLE_REC]: 'Agency',
    //     [this.ROLE_FOA]: 'Family Office',
    //     [this.ROLE_SUPER_ADMIN]: 'Super Admin',
    //
    // }
    // static readonly technicalProviderRoles = [SrRoles.ROLE_TECH_PROVIDER, SrRoles.ROLE_TECH_PROVIDER_AMBASSADOR];
    static  isTechnicalProvider(user: any): boolean {
        // console.log('isTechnicalProvider', user)
        if (!user?.role) return false;
        return [SrRoles.ROLE_PROVIDER_E, SrRoles.ROLE_PROVIDER_AM].includes(user.role);

    }

    static isAmbassadorUser(user: any): boolean {
        if (!user?.role) return false;
        return [ SrRoles.ROLE_PROVIDER_AM].includes(user.role);
    }
}

export const authorizationAccountMngt: { [key: string]: string[] }
    = {
    [SrRoles.ROLE_SUPER_ADMIN]:
        [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN,
            SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER, SrRoles.ROLE_PROVIDER_E],
    [SrRoles.ROLE_ADMIN]:
        [SrRoles.ROLE_ADMIN,
            SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER, SrRoles.ROLE_PROVIDER_E],
    [SrRoles.ROLE_FOA]:
        [SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    [SrRoles.ROLE_OWNER]: [SrRoles.ROLE_OWNER],
    [SrRoles.ROLE_REC]: [SrRoles.ROLE_REC],
}


export const authorizationTables = {
    systemParamsKeyModify: [SrRoles.ROLE_SUPER_ADMIN,],
    systemParamsValueModify: [SrRoles.ROLE_SUPER_ADMIN,SrRoles.ROLE_ADMIN],

    dashboardInterventionAccess: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    dashboardPollsAccess: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_OWNER],
    dashboardTenantsAccess: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    dashboardPointsAccess: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    dashboardManagementAccess: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    dashboardCollaborationAccess: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    dashboardProviderAccess: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    dashboardAdsAccess: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    dashboardLeaseAccess: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    dashboardOperationAccess: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    allBoUsers: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],

    /*
        FRONT-END:
        - config show/hide menu item (EDIT/DELETE/GENERATE) in config-form.component.ts
        - config guard for route (EDIT/NEW) in dashboard-routing.module.ts
        - config button ADD in config-form.component.html: add [addNewAllowedRoles]
        - for VIEW/LIST: only check/filter by API

        API:
        - for each API:
            - controller: build authorization filter, add filter to service call (if needed)
            - service:
                - add filter to query
                - for query result:
                    - if ONE:  NULL throw error
                    - if MANY: just return the result (empty array is ok)

     */

    accountsMngt: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN,],
    botsMngt: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, ],
    settingParamMngt: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],

    tenantRead: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    tenantCreate: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC,],
    tenantModify: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC,],
    tenantDelete: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],

    residenceAdd: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    residenceModify: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    residenceDelete: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN,],
    residenceViewOne: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,
        SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    residenceViewMany: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,
        SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    residenceViewManyIds: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,
        SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],


    apartmentAdd: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    apartmentDuplicate: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    apartmentModify: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    apartmentDelete: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    apartmentViewOne: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    apartmentViewMany: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],

    agencyAdd: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    agencyModify: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC,],
    agencyDelete: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    agencyViewOne: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    agencyViewMany: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],

    ownerAdd: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    ownerModify: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    ownerDelete: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    ownerViewOne: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC,
        SrRoles.ROLE_OWNER],
    ownerViewMany: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC,
        SrRoles.ROLE_OWNER],

    bailAdd: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN,],
    bailModify: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN,],
    bailDelete: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN,],
    bailViewOne: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    bailViewMany: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],

    sponsorAdd: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC],
    sponsorModify: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC,],
    sponsorDelete: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC],
    sponsorViewOne: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    sponsorViewMany: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],

    ambassadorAdd: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC],
    ambassadorModify: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC,],
    ambassadorDelete: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC],
    ambassadorViewOne: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    ambassadorViewMany: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],

    paymentAdd: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC],
    paymentModify: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC,],
    paymentDelete: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC],
    paymentViewOne: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    paymentViewMany: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],

    operationAdd: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC],
    operationModify: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC,],
    operationDelete: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    operationViewOne: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    operationViewMany: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],

    saleAdd: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC],
    saleModify: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC,],
    saleDelete: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC],
    saleViewOne: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    saleViewMany: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],

    providerAdd: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC],
    providerModify: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC,],
    providerDelete: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC],
    providerViewOne: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],
    providerViewMany: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER],

    pollAdd: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    pollModify: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    pollDelete: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    pollViewOne: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_OWNER,],
    pollViewMany: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA, SrRoles.ROLE_OWNER,],

    pointAdd: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_OWNER,],
    pointModify: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_OWNER,],
    pointDelete: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_OWNER,],
    pointViewOne: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_OWNER,],
    pointViewMany: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_OWNER,],


    collabAdd: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    collabModify: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    collabDelete: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    collabViewOne: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],
    collabViewMany: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_FOA,],

    interventionAdd: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN,],
    interventionModify: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER,],
    interventionDelete: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER,],
    interventionViewOne: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER,],
    interventionViewMany: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_REC, SrRoles.ROLE_OWNER,],

    catalogAdd: [SrRoles.ROLE_SUPER_ADMIN, SrRoles.ROLE_ADMIN, SrRoles.ROLE_OWNER, SrRoles.ROLE_FOA],


}
