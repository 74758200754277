import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FilterDialog} from '../../dialogs/filter.dialog';
import {getPreparedFilters} from '../../utils';

@Component({
    selector: 'sr-sub-header',
    templateUrl: './sub-header.component.html',
    styleUrls: ['./sub-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubHeaderComponent implements OnInit {

    @Input()
    heading: string | undefined;
    @Input()
    types: string[] | undefined;
    @Input()
    type: string | undefined;
    @Input()
    filters: any[] | undefined;
    @Input()
    search: string | undefined;
    @Output()
    filtersChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    onBack: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    addEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    typeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Input()
    statisticsActive: boolean = false;
    @Output()
    statisticsClick: EventEmitter<string> = new EventEmitter<string>();
    @Input()
    responsesActive: boolean = false;
    @Output()
    responsesClick: EventEmitter<string> = new EventEmitter<string>();

    @Output() onHelpClick = new EventEmitter();
    searchOpened = false;
    searchValue = '';

    constructor(
        private changes: ChangeDetectorRef,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
    }

    toggleSearch() {
        if (this.searchOpened) {
            const result = getPreparedFilters(this.filters);
            if (this.searchValue && this.search) {
                result[this.search] = {$regex: this.searchValue, $options: 'i'};
            }
            this.filtersChanged.emit(result);
            if (!this.searchValue) {
                this.searchOpened = false;
            }
            this.changes.detectChanges();
            return;
        }
        this.searchOpened = !this.searchOpened;
        this.changes.detectChanges();
    }

    changeType(type: string) {
        if (type === this.type) return;
        this.type = type;
        this.typeChanged.emit(type);
    }

    callFilterDialog(data: any) {
        this.dialog.open(FilterDialog, {
            data
        }).afterClosed().subscribe({
            next: (changed: boolean) => {
                if (!changed) return;
                const result = getPreparedFilters(this.filters);
                if (this.searchValue) {
                    result.title = {$regex: this.searchValue, $options: 'i'};
                }
                this.filtersChanged.emit(result);
            }
        })
    }

    showHelp: boolean = false;

    clickHelp() {
        this.showHelp = !this.showHelp;
        this.onHelpClick.emit();
    }
}
