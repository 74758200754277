<div class="sub-header">
    <button mat-icon-button
            *ngIf="onBack.observed"
            (click)="onBack.emit()"
            class="sub-header_back-button"
    >
        <mat-icon>keyboard_backspace</mat-icon>
    </button>

    <span class="sub-header_title" [title]="heading">
        {{ heading }}
    </span>

    <span *ngIf="onHelpClick.observed" style="margin-left: 10px">
            <mat-icon (click)="clickHelp()" [ngStyle]="{ 'color': showHelp ? '#B46EC2' :  'gray'  }">help</mat-icon>
    </span>

    <span class="type-select" *ngIf="type && types" [matMenuTriggerFor]="menu">
        {{ 'SUBHEADER.' + type | translate }}
        <mat-icon>chevron_right</mat-icon>
    </span>

    <mat-menu #menu="matMenu" class="subheader-type-select">
        <button mat-menu-item *ngFor="let item of types" [class.active]="item === type" (click)="changeType(item)">
            {{ 'SUBHEADER.' + item | translate }}
        </button>
    </mat-menu>

    <div
        class="search-container"
        *ngIf="search"
    >
        <input
            type="text"
            class="search-input"
            [ngClass]="{'active': searchOpened}"
            placeholder="Search..."
            [(ngModel)]="searchValue"
            (keyup.enter)="toggleSearch()"
        >
        <button
            class="search-btn"
            mat-icon-button
            (click)="toggleSearch()"
        >
            <mat-icon>search</mat-icon>
        </button>
    </div>

    <button
        mat-icon-button
        *ngIf="filters"
        class="filters-btn"
        [matMenuTriggerFor]="filtersMenu"
    >
        <mat-icon>tune</mat-icon>
    </button>

    <mat-menu #filtersMenu="matMenu" class="subheader-filters-select">
        <button mat-menu-item *ngFor="let item of filters" [class.active]="item.value" (click)="callFilterDialog(item)">
            {{ 'FILTERS.' + item.title | translate }}
        </button>
    </mat-menu>

    <button
        mat-icon-button
        *ngIf="addEvent.observed"
        class="add-btn"
        (click)="addEvent.emit()"
    >
        <mat-icon>add</mat-icon>
    </button>

    <button
        mat-icon-button
        *ngIf="statisticsClick.observed"
        class="stat-btn"
        [ngClass]="{'active': statisticsActive}"
        (click)="statisticsClick.emit()"
    >
        <mat-icon>auto_graph</mat-icon>
    </button>

    <button
        mat-icon-button
        *ngIf="responsesClick.observed"
        class="stat-btn"
        [ngClass]="{'active': responsesActive}"
        (click)="responsesClick.emit()"
    >
        <mat-icon>list</mat-icon>
    </button>

</div>
