import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import {UserService} from "../shared/services/user.service"; // Optional for keep-alive functionality
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = undefined;
    constructor(
        private translateService: TranslateService,
        private iconService: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private idle: Idle, private keepalive: Keepalive,
        private userService: UserService
    ) {
        this.initLocalization()
        this.runKeepAlive(idle, keepalive)
    }

    private safeURL = this.sanitizer.bypassSecurityTrustResourceUrl;

    ngOnInit(): void {

        this.initIconSet()
        // Listen for user interactions globally
        document.addEventListener('mousemove', () => this.onUserInteraction());
        document.addEventListener('click', () => this.onUserInteraction());
        document.addEventListener('keydown', () => this.onUserInteraction());
    }

    runKeepAlive(idle: Idle, keepalive: Keepalive) {
        // Set an idle timeout of 15 minutes (900 seconds)
        idle.setIdle(60*15); // Number of seconds of inactivity
        // After 15 minutes of inactivity, the user has 60 seconds to respond
        idle.setTimeout(5); // Number of seconds before session timeout
        // Define the user activity sources to interrupt the idle state (e.g., mouse, keyboard)
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        // When idle is detected, start the timeout warning countdown
        idle.onTimeoutWarning.subscribe((countdown) => {
            this.idleState = 'You will time out in ' + countdown + ' seconds!';
            console.log(this.idleState);
        });

        // When timeout occurs, log out the user
        idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            this.userService.logout();
        });

        // Optional: Keep alive ping every 15 minutes
        keepalive.interval(900);
        keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

        // Start watching for user inactivity
        this.resetIdleTimer();
    }

    // Resets the idle timer when user interacts
    resetIdleTimer() {
        this.idle.watch();
        this.idleState = 'Started idle timer.';
        this.timedOut = false;
    }

    // Explicit method to reset timer on specific actions
    onUserInteraction() {
        this.resetIdleTimer(); // Reset the timer whenever there's user activity
    }
    private initLocalization() {
        const supportedLangs = ['en', 'fr', 'de']
        this.translateService.addLangs(supportedLangs)
        this.translateService.setDefaultLang('fr')

        const browserLang = this.translateService.getBrowserLang() || 'fr'
        // this.translateService.use('fr');
        console.log('debug_lang_setting: browser', browserLang);

        // localStorage.removeItem('lang')
        const savedLang = localStorage.getItem('lang');
        console.log('debug_lang_setting: savedLang', savedLang);

        const currentLang = savedLang || browserLang;
        console.log('debug_lang_setting: currentLang', currentLang);

        if (currentLang && supportedLangs.includes(currentLang)) {
            console.log('debug_lang_setting: use currentLang', currentLang);
            this.translateService.use(currentLang)
        } else {
            console.log('debug_lang_setting: use FR', currentLang);
            this.translateService.use('fr')

        }
    }

    private initIconSet() {
        this.iconService.addSvgIcon(
            'pass_eye',
            this.safeURL('assets/icons/pass_eye.svg')
        )
        this.iconService.addSvgIcon(
            'app_store',
            this.safeURL('assets/icons/app_store.svg')
        )
        this.iconService.addSvgIcon(
            'play_market',
            this.safeURL('assets/icons/play_market.svg')
        )
        this.iconService.addSvgIcon(
            'service_interventions',
            this.safeURL('assets/icons/services/service_interventions.svg')
        )
        this.iconService.addSvgIcon(
            'service_polls',
            this.safeURL('assets/icons/services/service_polls.svg')
        )
        this.iconService.addSvgIcon(
            'service_points',
            this.safeURL('assets/icons/services/service_points.svg')
        )
        this.iconService.addSvgIcon(
            'service_stats',
            this.safeURL('assets/icons/services/service_stats.svg')
        )
        this.iconService.addSvgIcon(
            'service_lottery',
            this.safeURL('assets/icons/services/service_lottery.svg')
        )
        this.iconService.addSvgIcon(
            'service_tenants',
            this.safeURL('assets/icons/services/service_tenants.svg')
        )
        this.iconService.addSvgIcon(
            'service_phone_book',
            this.safeURL('assets/icons/services/service_phone_book.svg')
        )
        this.iconService.addSvgIcon(
            'service_operations',
            this.safeURL('assets/icons/services/service_operations.svg')
        )
        this.iconService.addSvgIcon(
            'service_bails',
            this.safeURL('assets/icons/services/service_bails.svg')
        )
        this.iconService.addSvgIcon(
            'service_collaborative',
            this.safeURL('assets/icons/services/service_collaborative.svg')
        )
        this.iconService.addSvgIcon(
            'service_advertisement',
            this.safeURL('assets/icons/services/service_advertisement.svg')
        )
        this.iconService.addSvgIcon(
            'service_accounts',
            this.safeURL('assets/icons/services/service_accounts.svg')
        )
        this.iconService.addSvgIcon(
            'dashboard',
            this.safeURL('assets/icons/services/dashboard.svg')
        )
        // rectangular options
        this.iconService.addSvgIcon(
            'option-edit',
            this.safeURL('assets/icons/rectangular-options/edit.svg')
        )
        this.iconService.addSvgIcon(
            'option-cancel',
            this.safeURL('assets/icons/rectangular-options/cancel.svg')
        )
        this.iconService.addSvgIcon(
            'option-delete',
            this.safeURL('assets/icons/rectangular-options/delete.svg')
        )
        this.iconService.addSvgIcon(
            'option-chart',
            this.safeURL('assets/icons/rectangular-options/bar-chart.svg')
        )

        this.iconService.addSvgIcon(
            'option-terminate',
            this.safeURL('assets/icons/rectangular-options/eye-close.svg')
        )
        this.iconService.addSvgIcon(
            'option-duplicate',
            this.safeURL('assets/icons/rectangular-options/duplicate.svg')
        )
        this.iconService.addSvgIcon(
            'option-look',
            this.safeURL('assets/icons/rectangular-options/look.svg')
        )
        this.iconService.addSvgIcon(
            'option-play',
            this.safeURL('assets/icons/rectangular-options/play.svg')
        )
        // categories
        this.iconService.addSvgIcon(
            'category-carpentry',
            this.safeURL('assets/icons/categories/carpentry.svg')
        )
        this.iconService.addSvgIcon(
            'category-garden',
            this.safeURL('assets/icons/categories/garden.svg')
        )
        this.iconService.addSvgIcon(
            'category-heat',
            this.safeURL('assets/icons/categories/heat.svg')
        )
        this.iconService.addSvgIcon(
            'category-intercom',
            this.safeURL('assets/icons/categories/intercom.svg')
        )
        this.iconService.addSvgIcon(
            'category-ironwork',
            this.safeURL('assets/icons/categories/ironwork.svg')
        )
        this.iconService.addSvgIcon(
            'category-lifebuoy',
            this.safeURL('assets/icons/categories/lifebuoy.svg')
        )
        this.iconService.addSvgIcon(
            'category-lift',
            this.safeURL('assets/icons/categories/lift.svg')
        )
        this.iconService.addSvgIcon(
            'category-masonry',
            this.safeURL('assets/icons/categories/masonry.svg')
        )
        this.iconService.addSvgIcon(
            'category-roof',
            this.safeURL('assets/icons/categories/roof.svg')
        )
        this.iconService.addSvgIcon(
            'category-window',
            this.safeURL('assets/icons/categories/window.svg')
        )
        this.iconService.addSvgIcon(
            'category-electricity',
            this.safeURL('assets/icons/categories/electricity.svg')
        )
        this.iconService.addSvgIcon(
            'category-locksmith',
            this.safeURL('assets/icons/categories/locksmith.svg')
        )
        this.iconService.addSvgIcon(
            'category-maintain',
            this.safeURL('assets/icons/categories/maintain.svg')
        )
        this.iconService.addSvgIcon(
            'category-plumbing',
            this.safeURL('assets/icons/categories/plumbing.svg')
        )
        this.iconService.addSvgIcon(
            'category-sanitation',
            this.safeURL('assets/icons/categories/sanitation.svg')
        )
        // polls menu
        this.iconService.addSvgIcon(
            'polls-text',
            this.safeURL('assets/icons/polls-menu/free-text.svg')
        )
        this.iconService.addSvgIcon(
            'polls-single',
            this.safeURL('assets/icons/polls-menu/single-select.svg')
        )
        this.iconService.addSvgIcon(
            'polls-multiple',
            this.safeURL('assets/icons/polls-menu/multiple-select.svg')
        )
        this.iconService.addSvgIcon(
            'polls-image',
            this.safeURL('assets/icons/polls-menu/image-select.svg')
        )
        this.iconService.addSvgIcon(
            'polls-eval',
            this.safeURL('assets/icons/polls-menu/evaluation-select.svg')
        )

        this.iconService.addSvgIcon(
            'option-up',
            this.safeURL('assets/icons/polls-menu/icon-up.svg')
        )

        this.iconService.addSvgIcon(
            'option-down',
            this.safeURL('assets/icons/polls-menu/icon-down.svg')
        )
        // Other
        this.iconService.addSvgIcon(
            'export-pdf',
            this.safeURL('assets/icons/export_pdf.svg')
        )
        this.iconService.addSvgIcon(
            'export-xls',
            this.safeURL('assets/icons/export_xls.svg')
        )
    }
}
