<div class="rectangular-element" [class.active]="isActive">
    <button class="options-icon" mat-icon-button *ngIf="options && options.length" [matMenuTriggerFor]="optionsMenu">
        <mat-icon>more_vert</mat-icon>
    </button>
    <p class="title" [title]="title" style="height: 20px">{{ title }}</p>
    <p class="date" *ngIf="date">{{ date ? formatDate(date, true) : '' }}</p>
    <p class="description" style="height: 16px">{{ description }}</p>
    <p class="description"
       style="margin: 0; white-space: pre-line;  text-overflow: ellipsis; overflow: hidden;  ">{{ subDescription }}</p>
    <p class="description"
       style="margin: 0; white-space: pre-line; height: 20px; font-size: 12px; font-style: italic"> {{ subDescription2 }} </p>
    <div class="btn-container" style="margin: 0" *ngIf="type === 'statistics' && actionClick.observed && actionText">
        <button
            class="sr-kit_button" style="padding: 1px 0;" [ngStyle]="{'background-color': isPurple ? '#B46EC2'
                                                                            : (!isActive ? '#95A2AD'
                                                                            : (isActive2 ? '#B46EC2' : 'white')),

                                                            'color': isActive2 ? 'white' : 'black'
                                                                          }"
            data-color="statistics"
            data-size="m"
            (click)="actionClick.emit(value)"
        >
            {{ actionText }}
        </button>
    </div>

    <div class="btn-container" style="margin: 0" *ngIf="lotteryText">
        <button
            class="sr-kit_button" style="padding: 2px 0; height: 40px; font-size: 14px; line-height: 14px; text-align: left; padding-left: 8px"
            [ngStyle]="{'background-color': 'white',
                                                            'color': 'black'
                                                                          }"
            data-color="statistics"
            data-size="m"
            (click)="actionClick.emit(value)"
        >
            {{ lotteryText.split('\n')[0] }} <br> {{ lotteryText.split('\n')[1] }}
        </button>
    </div>

    <div class="btn-container" style="margin-top: 0" *ngIf="type === 'action' && actionLink && actionText">
        <button class="sr-kit_button"

                data-color="primary"
                data-size="s"
                [routerLink]="actionLink"
        >{{ actionText }}
        </button>
    </div>

    <!--    style="margin-bottom: 10px"-->
    <div class="btn-container" style="margin-top: 0" *ngIf="type === 'action' && actionClick.observed && actionText">

        <button class="sr-kit_button"

                data-color="primary"
                data-size="ss"
                (click)="actionClick.emit(value)"
                [disabled]="actionDisabled"
        >
            {{ actionText }}
        </button>

    </div>
    <mat-menu #optionsMenu="matMenu" class="rectangular-options-menu" xPosition="before">
        <ng-container *ngFor="let option of options">
            <button
                mat-menu-item
                (click)="option.cb(value)"
                [ngClass]="{'red': option.highlight}"
                *ngIf="!isHide(option) && !option.active && !option.inactive"
                [disabled]="isDisabled(option)"
            >
                <mat-icon [svgIcon]="option.icon"></mat-icon>
                {{ 'MENU_OPTIONS.' + option.title | translate }}
            </button>
            <button
                mat-menu-item
                (click)="option.cb(value)"
                *ngIf="!isHide(option) &&option.active && value.activated"
                [disabled]="isDisabled(option)"
            >
                <mat-icon [svgIcon]="option.icon"></mat-icon>
                {{ 'MENU_OPTIONS.' + option.title | translate }}
            </button>
            <button
                mat-menu-item
                (click)="option.cb(value)"
                *ngIf="!isHide(option) &&option.inactive && !value.activated"
                [disabled]="isDisabled(option)"
            >
                <mat-icon [svgIcon]="option.icon"></mat-icon>
                {{ 'MENU_OPTIONS.' + option.title | translate }}
            </button>
        </ng-container>
    </mat-menu>
</div>
